import $ from "jquery";

$(function () {
    $(".js-toggle-section").on("click", function (e) {
        let id = $(this).attr("href")
            ? $(this).attr("href")
            : $(this).attr("data-toggle-target");
        $(id).slideDown(1000);
        $("html, body").animate(
            {
                scrollTop: $(id).offset().top,
            },
            1000
        );
    });
});
